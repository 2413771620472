module.exports = {
    siteTitle: "Daily Spikes", // <title>
    shortSiteTitle: "DailySpikes", // <title> ending for posts and pages
    siteDescription: "Love, Dream, Inspire",
    siteUrl: "https://www.dailyspikes.com",
    twitterUsername:"DailySpikes",
    hashTag:"DailySpikes",
    // pathPrefix: "",
    siteImage: "preview.jpg",
    siteLanguage: "en",
  
    /* author */
    authorName: "greg lobinski",
    authorTwitterAccount: "greglobinski",
  
    /* info */
    headerTitle: "greg lobinski",
    headerSubTitle: "presents another one GatsbyJS starter",
  
  
    // gravatar
    // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
    // Replace your email adress with md5-code.
    // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
    // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
    gravatarImgMd5: "",
  
    // social
    authorSocialLinks: [
      { name: "github", url: "https://github.com/greglobinski" },
      { name: "twitter", url: "https://twitter.com/greglobinski" },
      { name: "facebook", url: "http://facebook.com/greglobinski" }
    ]
  };