import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import config from '../config/config'


class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const postLinks = posts.map(post => (
      <li key={post.node.frontmatter.slug}>
        <Link to={'/'+post.node.frontmatter.templateKey+'/'+post.node.frontmatter.slug}>
          <h2 className="is-size-2">{post.node.frontmatter.title}</h2>
        </Link>
      </li>
    ))
    const tag = this.props.pageContext.tag
    const totalCount = this.props.data.allMarkdownRemark.totalCount
    const tagHeader = `${totalCount} post${
      totalCount === 1 ? '' : 's'
    } tagged with “${tag}”`

    return (
      <Layout>
        
          <Helmet title={`${tag} | ${config.siteTitle}`} 
            meta={[
                { name: 'description', content: '' },
                { name: 'keywords', content: ', , ' },
            ]}    
          />

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>{tagHeader}</h1>
                    </header>
                    <div className="content">
                        <ul style={{listStyle:'none'}} className="taglist">{postLinks}</ul>
                    </div>
                </div> 
            </section>
        </div>
          
        
      </Layout>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
            id
            frontmatter {
              slug
              templateKey
              title
              date(formatString: "MMMM DD, YYYY")
              author
              published
            }

        }
      }
    }
  }
`
